import { formatInTimeZone } from 'date-fns-tz'
import { addMinutes } from 'date-fns'

/**
 * Formatea hora
 * @param {string} date
 */
const formatDateHelper = ({ date, formatDate = 'HH:mm', add }) => {
  if (add) {
    date = addMinutes(new Date(date), add)
  } else {
    date = new Date(date)
  }
  return formatInTimeZone(date, 'America/Santiago', formatDate)
}

export default formatDateHelper
