import { formatInTimeZone } from 'date-fns-tz'
import { es } from 'date-fns/locale'
import capitalize from 'lodash/capitalize'

/**
 * Formatea fechas en base a timezone local
 * @param {string} date
 */
const formatDateHelper = (date: Date, formatDate = 'EEEE d/M/yy') => {
  date = new Date(date)
  const result = formatInTimeZone(date, 'America/Santiago', formatDate, { locale: es })
  return capitalize(result)
}

export default formatDateHelper
