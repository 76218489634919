import React from 'react'
import { View as ViewUi } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import classnames from 'classnames'

/**
 * View component
 */
export const View = React.forwardRef(({ tw: twProps, children }, ref) => {
  const tailwind = useTailwind()
  return (
    <ViewUi style={tailwind(classnames(twProps))} ref={ref}>
      {children}
    </ViewUi>
  )
})
