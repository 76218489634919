import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { supabase } from '~/libs/api'
import { View, Text, InputText, Button } from '~/components'

export default function ModalAuth({ onClose, showMore }) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })
  const onSubmit = (data) => handleLogin(data)

  const handleLogin = async (data) => {
    try {
      setIsLoading(true)
      const { email } = data
      const { error } = await supabase.auth.signIn(
        { email },
        {
          redirectTo: process.env.URL,
        }
      )
      if (error) throw error
      onClose()
      setTimeout(() => alert('Revisa tu email para iniciar sesión en PlayPat'), 300)
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <>
      {showMore === true ? (
        <Text tw="mb-4">Primero debes ingresar con tu email para poder unirte a un partido</Text>
      ) : (
        <Text tw="mb-4">
          Ingresa tu email para enviar un enlace de validación y continuar en PlayPat
        </Text>
      )}

      <View tw="mb-4">
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Ingresa un email válido',
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputText
              placeholder="email"
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              onKeyPress={({ nativeEvent: { key: keyValue } }) =>
                keyValue === 'Enter' && handleSubmit(onSubmit)()
              }
              keyboardType="email"
              autoCompleteType="email"
              textContentType="emailAddress"
            />
          )}
          name="email"
        />
        <View tw="mt-2">
          {errors.email && <Text tw="text-xs text-danger">Campo requerido</Text>}
        </View>
      </View>

      <View>
        <Button
          label="Enviar"
          type="primary"
          onPress={handleSubmit(onSubmit)}
          disabled={isLoading}
          loading={isLoading}
          showIcon={true}
        />
      </View>
    </>
  )
}
