import React from 'react'
import { ImageBackground as ImageBackgroundUi } from 'react-native'
import { useTailwind } from 'tailwind-rn'

/**
 * Image component
 */
export const ImageBackground = ({ tw: twProps, children, ...props }) => {
  const tailwind = useTailwind()
  return (
    <ImageBackgroundUi style={tailwind(twProps)} {...props}>
      {children}
    </ImageBackgroundUi>
  )
}
