import React from 'react'
import { TextInput } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import classnames from 'classnames'

/**
 * InputText component
 */
export const InputText = ({
  type,
  size = 'base',
  label,
  isDisabled,
  isSubmit,
  isLoading,
  ...props
}) => {
  const tailwind = useTailwind()

  const wrapperClass = tailwind(
    classnames('relative rounded-xl items-center', {
      'bg-violet-600 text-white': type === 'primary' && !isDisabled,
      'bg-secondary text-white': type === 'secondary',
      'bg-background border-outline': type === 'tertiary' && !isDisabled,
      'py-2 px-14': size === 'base',
      'py-2 px-10 text-sm': size === 'sm',
      'py-3 px-20': size === 'lg',
      'py-2 px-10 w-full': size === 'full',
      'bg-outlineSecondary text-white': isDisabled === true,
    })
  )

  return (
    <TextInput
      {...props}
      style={tailwind(
        classnames(
          'font-bold text-white bg-primary border-border border-2 rounded-lg py-2 px-4 outline-none',
          {
            'text-base': size === 'base',
            'text-sm': size === 'sm',
          }
        )
      )}
    />
  )
}
