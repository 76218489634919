import React, { useState, useEffect } from 'react'
import { ScrollView, Platform, ImageBackground } from 'react-native'
import { useRealtime, useFilter, useSubscription } from 'react-supabase'
import { observer } from 'mobx-react-lite'
import { formatInTimeZone } from 'date-fns-tz'
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash'

import { useStore } from '~/store/StoreProvider'
import { View, Text, Match, Icon, Modal, TouchableOpacity, Top, Image } from '~/components'
import { MatchScreen } from '~/screens'

export default observer(function HomeScreen({ navigation }) {
  const { authStore } = useStore()
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1224,
  })

  const [openModalMatch, setOpenModalMatch] = useState(false)
  const [matches, setMatches] = useState([])
  const [matchData, setMatchData] = useState(null)
  const [filter, setFilter] = useState('all')

  // Obtiene todos los partidos
  const currentDate = formatInTimeZone(new Date(), 'America/Santiago', 'yyyy-MM-dd HH:mm:ssXXX')
  const [{ data, fetching }, reexecuteMatches] = useRealtime('matches', {
    select: {
      columns: [
        '*',
        'locations!inner(*)',
        'users!owner_id(id,first_name,last_name)',
        'requests!left(*, users!left(id,first_name,last_name))',
      ].join(','),
      filter: useFilter((query) => query.gte('date', currentDate), []),
    },
  })

  // Subscripcion a todos los eventos
  useSubscription(() => reexecuteMatches(), {
    event: '*',
    table: '*',
  })

  // Revisa data actual de partidos
  // Si hay un partido abierto vuelve a pasar data
  useEffect(() => {
    if (data) {
      if (data && data?.length > 0 && matchData) {
        const findMatch = data.find((item) => item.id === matchData.id)
        setMatchData(findMatch)
      }
      setMatches(_.orderBy(data, ['date']))
    }
  }, [data])

  // Busca partidos por query param id
  // y abre modal de partido si encuentra
  useEffect(() => {
    const queryString = window.location.search

    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      const matchId = urlParams.get('id')

      if (data && data.length > 0) {
        const findMatch = data.find((item) => item.id === parseInt(matchId))
        if (findMatch) {
          handleOpenModalMatch(findMatch)
        }
        window.history.pushState({}, document.title, '/')
      }
    }
  }, [window.location.search, data])

  const handleOpenModalMatch = (data) => {
    setOpenModalMatch(true)
    setMatchData(data)
  }

  const handleFilter = (filterSelected) => {
    let filtered = null
    setFilter(filterSelected)

    if (filterSelected === 'all') {
      filtered = data
    } else if (filterSelected === 'matches') {
      filtered = data.filter((item) => item.owner_id === authStore.userData.id)
    } else if (filterSelected === 'requests') {
      filtered = data.reduce((acc, item) => {
        if (item?.requests?.some((request) => request.user_id === authStore.userData.id)) {
          return [...acc, item]
        } else {
          return acc
        }
      }, [])
    }
    setMatches(_.orderBy(filtered, ['date']))
  }

  const filters = [
    { label: 'Todos', value: 'all' },
    { label: 'Mis partidos', value: 'matches' },
    { label: 'Solicitudes', value: 'requests' },
    // { label: 'Cerrados', value: 'closed' },
  ]

  return (
    <View tw="flex-1 bg-background">
      <ScrollView>
        <View tw="absolute w-full opacity-40">
          <View tw="sm:hidden">
            <Image
              resizeMode="cover"
              source={require('~/assets/images/bg-header-1.jpg')}
              style={{ height: 200 }}
            />
          </View>
          <View tw="hidden sm:flex">
            <Image
              resizeMode="cover"
              source={require('~/assets/images/bg-header-1-lg.jpg')}
              style={{ height: 440 }}
            />
          </View>
        </View>

        <Top onCreateMatch={async () => await reexecuteMatches()} />

        <View tw="px-6 lg:px-8 mt-8">
          {/* Lista de partidos */}
          <View tw="mb-4 md:flex-row gap-y-2 justify-between items-center">
            <View tw="flex-row items-center">
              <Text tw="text-brand text-sm">Próximos partidos en Valdivia</Text>
              <Icon name="arrow-down" tw="text-brand ml-1" />
            </View>

            {/* Filtros */}
            {authStore.isAuth === true && (
              <View tw="flex-row gap-2 items-center ">
                {filters.map((item, key) => (
                  <TouchableOpacity onPress={() => handleFilter(item.value)} key={key}>
                    <View
                      tw={{
                        'bg-primary rounded-xl px-3 py-1': true,
                        'bg-brand': item.value === filter,
                      }}>
                      <Text
                        tw={{
                          'uppercase text-xs text-zinc-400': true,
                          'text-background': item.value === filter,
                        }}>
                        {item.label}
                      </Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            )}
          </View>

          {matches?.length > 0 ? (
            <View tw="grid md:grid-cols-3 gap-6 pb-6">
              {matches.map((item, key) => (
                <TouchableOpacity
                  key={key}
                  onPress={() =>
                    Platform.OS === 'web'
                      ? handleOpenModalMatch(item)
                      : navigation.navigate('match', { id: item.id })
                  }>
                  <Match data={item} navigation={navigation} showConfirmed={true} />
                </TouchableOpacity>
              ))}
            </View>
          ) : (
            <>
              {filter === 'matches' && <Text tw="text-zinc-400">No tienes partidos creados</Text>}
              {filter === 'requests' && (
                <Text tw="text-zinc-400">No tienes solicitudes de partidos</Text>
              )}
            </>
          )}

          {/* Modal match */}
          <Modal open={openModalMatch} onClose={() => setOpenModalMatch(false)} type="full">
            <MatchScreen data={matchData} onClose={() => setOpenModalMatch(false)} />
          </Modal>
        </View>
      </ScrollView>
    </View>
  )
})
