import React, { useState } from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { eachDayOfInterval, addDays, set } from 'date-fns'
import { useInsert } from 'react-supabase'
import { useForm, Controller } from 'react-hook-form'

import { useStore } from '~/store/StoreProvider'
import { Text, View, Button, PickerSelect, Modal, Match, Icon } from '~/components'
import { formatDateHelper } from '~/helpers'

function time(start, end, interval) {
  var s = start.split(':').map((e) => +e)
  var e = end.split(':').map((e) => +e)
  var res = []
  var t = []
  while (!(s[0] == e[0] && s[1] > e[1])) {
    t.push(s[0] + ':' + (s[1] < 10 ? '0' + s[1] : s[1]))
    s[1] += interval
    if (s[1] > 59) {
      s[0] += 1
      s[1] %= 60
    }
  }
  for (var i = 0; i < t.length - 1; i++) {
    res.push({ label: t[i], value: `${t[i]}` })
  }
  return res
}

const hours = time('05:00', '24:00', 30)

const durations = [
  { label: '60 minutos', value: '60' },
  { label: '90 minutos', value: '90' },
  { label: '120 minutos', value: '120' },
]

const locations = [
  { label: 'Las Marías Pádel', value: '1' },
  { label: 'Espacio Pádel', value: '2' },
  { label: 'Club Phoenix', value: '3' },
  { label: 'Physical Pádel', value: '4' },
  { label: 'Valdivia Pádel Center', value: '5' },
  { label: 'Centro Español', value: '6' },
]

const cats = [
  { label: '6ta iniciante', value: '6_lower' },
  { label: '6ta', value: '6' },
  { label: '6ta avanzada', value: '6_high' },
  { label: '5ta', value: '5' },
  { label: '4ta', value: '4' },
  { label: '3ra', value: '3' },
  { label: '2da', value: '2' },
  { label: '1ra', value: '1' },
]

const types = [
  { label: 'Doble masculino', value: 'double_male' },
  { label: 'Doble femenino', value: 'double_female' },
  { label: 'Doble mixto', value: 'double_mixed' },
  // { label: 'Single masculino', value: 'single_male' },
  // { label: 'Single femenino', value: 'single_female' },
]

const playersSlots = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
]

const locationCourt = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
]

/**
 * Retorna lista de proximos 6 días
 * @returns Array
 */
const getDays = () => {
  const result = eachDayOfInterval({
    start: new Date(),
    end: addDays(new Date(), 6),
  })
  return result.map((item) => {
    return { label: formatDateHelper(item), value: `${item}` }
  })
}

export default observer(function CreateScreen({ onClose, onCreate }) {
  const { authStore } = useStore()
  const [{}, execute] = useInsert('matches')
  const [isLoading, setIsLoading] = useState(false)
  const [model, setModel] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async ({
    date,
    time,
    playersSlots,
    duration,
    location,
    courtNumber,
    category,
    type,
  }) => {
    try {
      time = time.split(':')
      date = new Date(date)
      date = set(date, { hours: parseInt(time[0]), minutes: parseInt(time[1]) })

      const model = {
        date,
        duration,
        location_id: location,
        court_number: courtNumber,
        category,
        type,
        owner_id: authStore.userData.id,
        players_slots: playersSlots,
      }
      setModel(model)
      setOpenModal(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreateMatch = async () => {
    try {
      setIsLoading(true)
      await execute(model)
      onClose()
      onCreate()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View tw="relative p-6 pb-44">
      <Text tw="font-bold text-lg text-brand self-center mb-6 tracking-wide">Crear partido</Text>

      <View tw="grid gap-y-6 mb-6">
        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="ionic" size={17} name="tennisball-outline" tw="mr-2" />
            <Text>¿Cuántos jugadores te faltan?</Text>
          </View>
          <View>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="playersSlots"
              render={({ field: { onChange, onBlur, value } }) => (
                <PickerSelect
                  placeholder="Nº de jugadores..."
                  items={playersSlots}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </View>
        </View>

        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="feather" size={17} name="calendar" tw="mr-2" />
            <Text>Fecha y Hora</Text>
          </View>

          <View tw="grid gap-2 lg:grid-cols-2">
            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="date"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona el día..."
                    items={getDays()}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>

            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="time"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona la hora..."
                    items={hours}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>
          </View>
        </View>

        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="ionic" size={17} name="time-outline" tw="mr-2" />
            <Text>Duración</Text>
          </View>
          <View>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              defaultValue="60"
              name="duration"
              render={({ field: { onChange, onBlur, value } }) => (
                <PickerSelect
                  placeholder="Seleccionar una duración..."
                  items={durations}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </View>
        </View>

        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="ionic" size={17} name="location-outline" tw="mr-2" />
            <Text>Lugar</Text>
          </View>

          <View tw="grid gap-2 lg:grid-cols-2">
            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="location"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona un lugar..."
                    items={locations}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>

            <View>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="courtNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Nº de cancha..."
                    items={locationCourt}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>
          </View>
        </View>

        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="ionic" size={17} name="tennisball-outline" tw="mr-2" />
            <Text>Categoría</Text>
          </View>
          <View>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="category"
              render={({ field: { onChange, onBlur, value } }) => (
                <PickerSelect
                  placeholder="Selecciona un categoría..."
                  items={cats}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </View>
        </View>

        <View>
          <View tw="flex-row items-center mb-2">
            <Icon type="ionic" size={17} name="tennisball-outline" tw="mr-2" />
            <Text>Tipo de partido</Text>
          </View>
          <View>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="type"
              render={({ field: { onChange, onBlur, value } }) => (
                <PickerSelect
                  placeholder="Selecciona un tipo..."
                  items={types}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </View>
        </View>
      </View>

      {Object.keys(errors).length > 0 && (
        <Text tw="mb-2 text-xs text-danger">Debes completar todos los campos para continuar</Text>
      )}

      <Button
        showIcon={true}
        label="Crear partido"
        type="primary"
        onPress={handleSubmit(onSubmit)}
      />

      {/* Modal confirmación */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Text tw="mb-2">¿Confirmas que deseas crear el siguiente partido?</Text>
        <View tw="mb-3">
          <Match data={model} showMore={true} />
        </View>

        <View tw="mb-3">
          <Button
            label="Crear"
            type="primary"
            onPress={() => handleCreateMatch()}
            loading={isLoading}
            showIcon={true}
          />
        </View>
        <Button size="sm" label="Cancelar" onPress={() => setOpenModal(false)} />
      </Modal>
    </View>
  )
})
