import { action, observable, makeObservable, runInAction, computed } from 'mobx'

import { supabase } from '~/libs/api'

class AuthStore {
  constructor() {
    makeObservable(this, {
      isAuth: observable,
      userData: observable,
      isFullUser: computed,
      fullName: computed,
      setUserData: action,
      logout: action,
    })
  }

  isAuth = false
  userData = {}

  get isFullUser() {
    return (
      this.userData?.first_name?.length > 0 &&
      this.userData?.last_name?.length > 0 &&
      String(this.userData?.phone).length > 8
    )
  }

  get fullName() {
    return this.isFullUser ? `${this.userData.first_name} ${this.userData.last_name}` : false
  }

  async resolveUserData(session = false) {
    const _session = session || supabase.auth.session()
    const _storageData = JSON.parse(localStorage.getItem('supabase.auth.data'))

    if (!_storageData && _session?.user?.id) {
      const { data } = await supabase.from('users').select('*').eq('id', _session?.user?.id)
      if (data && data[0]) {
        this.setUserData(data[0])
      }
    } else {
      this.setUserData(_storageData)
    }
  }

  /**
   * Revisa estado de usuario
   * asigna datos locales si existen
   */
  setUserData(data) {
    try {
      if (data) {
        runInAction(() => {
          this.isAuth = true
          this.userData = data
        })
        localStorage.setItem('supabase.auth.data', JSON.stringify(data))
      }
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Actualiza datos usuario
   */
  updateUserData(data) {
    try {
      if (data) {
        const _data = {
          ...this.userData,
          ...data,
        }
        localStorage.setItem('supabase.auth.data', JSON.stringify(_data))
        runInAction(() => {
          this.userData = _data
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  async logout() {
    try {
      await supabase.auth.signOut()
      runInAction(() => {
        this.isAuth = false
        this.userData = {}
        localStorage.removeItem('supabase.auth.data')
      })
    } catch (error) {
      console.error(error)
    }
  }
}

const authStore = new AuthStore()

export default authStore
