import React from 'react'
import { Text as TextUi } from 'react-native'
import PropTypes from 'prop-types'
import { useTailwind } from 'tailwind-rn'
import classnames from 'classnames'

/**
 * Text component
 */
export const Text = ({ children, size, tw: twProps }) => {
  const tailwind = useTailwind()
  return (
    <TextUi
      style={tailwind(
        classnames(
          `font-medium tracking-wide text-sm text-zinc-300`,
          {
            'text-sm': size === 'sm',
          },
          twProps
        )
      )}>
      {children}
    </TextUi>
  )
}

Text.propTypes = {
  /**
   * Type
   */
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /**
   * isDisabled
   */
  isDisabled: PropTypes.bool,
  /**
   * isSubmit
   */
  isSubmit: PropTypes.bool,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
  /**
   * Size
   */
  size: PropTypes.oneOf(['sm', 'base', 'lg', 'full']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
}

Text.defaultProps = {
  size: 'base',
  type: 'primary',
  label: 'Button',
  onClick: undefined,
}
