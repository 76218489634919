import React from 'react'
import { TouchableWithoutFeedback as TouchableWithoutFeedbackUi } from 'react-native'
import { useTailwind } from 'tailwind-rn'

/**
 * TouchableWithoutFeedback component
 */
export const TouchableWithoutFeedback = ({ children, tw: twProps, ...props }) => {
  const tailwind = useTailwind()
  return (
    <TouchableWithoutFeedbackUi style={tailwind(twProps)} {...props}>
      {children}
    </TouchableWithoutFeedbackUi>
  )
}
