import React from 'react'

import { View, Image, Text } from '~/components'

export default function Logo() {
  return (
    <View>
      <View tw="flex-row items-center content-start justify-start select-none">
        <Image
          source={require('~/assets/images/lgo-playpat.png')}
          resizeMode="contain"
          tw="w-28 h-8 md:w-36 md:h-10 md:-ml-1"
        />
        <View tw="mt-2 ml-1 absolute -bottom-3 left-0">
          <Text tw="uppercase text-xxs text-zinc-500">BETA</Text>
        </View>
      </View>
    </View>
  )
}
