import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useMediaQuery } from 'react-responsive'

import {
  Text,
  View,
  Logo,
  Button,
  Modal,
  ModalAuth,
  Icon,
  TouchableOpacity,
  ModalAuthData,
} from '~/components'
import { useStore } from '~/store/StoreProvider'
import { CreateScreen } from '~/screens'
import { useHandleUser } from '~/hooks'

export default observer(function Top({ onCreateMatch }) {
  const { authStore } = useStore()
  const [openModalAuth, setOpenModalAuth] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openModalAuthData, setOpenModalAuthData] = useState(false)
  const [isEditModeProfile, setEditModeProfile] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setOpenMenu(false) })
  const isNotFullUser = useHandleUser()
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1224,
  })

  // Revision data completa de usuario
  useEffect(() => {
    if (isNotFullUser === true) {
      setOpenModalAuthData(true)
    }
  }, [isNotFullUser])

  const handleLogout = () => {
    setOpenMenu(false)
    setTimeout(() => {
      if (confirm('¿Seguro que deseas cerrar sesión?')) {
        authStore.logout()
      }
    }, 100)
  }

  const handleOpenEditProfile = () => {
    setOpenMenu(false)
    setOpenModalAuthData(true)
    setEditModeProfile(true)
  }

  return (
    <View tw="relative z-20">
      <View tw="mx-6 lg:mx-8 mt-6">
        <Logo />

        {authStore.isAuth === true && (
          <>
            <View tw="absolute top-0 right-0 z-20">
              <View tw="flex-row items-center">
                {authStore.isFullUser && (
                  <View tw="flex-row justify-center md:justify-end">
                    <Button
                      label="Crear partido"
                      type="primary"
                      size={isTabletOrMobileDevice === true ? 'xs' : 'sm'}
                      onPress={() => setOpenModalCreate(true)}
                    />
                  </View>
                )}

                <View ref={ref} tw="items-center ml-2">
                  <TouchableOpacity
                    tw="flex-row justify-end items-center content-center py-1.5"
                    onPress={() => setOpenMenu(!openMenu)}>
                    <Icon type="ionic" size={20} name="person-outline" />
                    <Text tw="text-xs md:text-sm mx-1 hidden sm:flex">{authStore.fullName}</Text>
                    <Icon type="ionic" size={12} name="chevron-down-outline" />
                  </TouchableOpacity>
                </View>
              </View>

              {/* Menu */}
              {openMenu && (
                <View tw="bg-background rounded-xl w-56 absolute top-8 -right-1 p-2 gap-2 z-20">
                  {/* Editar perfil */}
                  <TouchableOpacity
                    onPress={handleOpenEditProfile}
                    tw="flex-row items-center justify-between bg-primary py-2 px-4 rounded-xl">
                    <Text tw="text-xs">Editar perfil</Text>
                    <Icon type="ionic" size={17} name="person-outline" tw="ml-1" />
                  </TouchableOpacity>
                  {/* Cerrar sesión */}
                  <TouchableOpacity
                    tw="flex-row items-center justify-between bg-primary py-2 px-4 rounded-xl z-10"
                    onPress={handleLogout}>
                    <Text tw="text-xs">Cerrar sesión</Text>
                    <Icon type="ionic" size={17} name="log-out-outline" tw="ml-1" />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </>
        )}

        {authStore.isAuth === false && (
          <View tw="absolute top-0 right-0 z-10">
            <Button
              label="Ingresar"
              type="primary"
              size="sm"
              onPress={() => setOpenModalAuth(true)}
            />
          </View>
        )}

        {/* Modal crear match */}
        <Modal open={openModalCreate} onClose={() => setOpenModalCreate(false)} type="full">
          <CreateScreen
            onClose={() => setOpenModalCreate(false)}
            onCreate={() => onCreateMatch()}
          />
        </Modal>

        {/* Modal autenticación */}
        <Modal open={openModalAuth} onClose={() => setOpenModalAuth(false)}>
          <ModalAuth onClose={() => setOpenModalAuth(false)} />
        </Modal>

        {/* Modal perfil usuario (tambien aparece post signup) */}
        <Modal
          open={openModalAuthData}
          onClose={() => setOpenModalAuthData(false)}
          disableClose={isEditModeProfile ? false : true}>
          <ModalAuthData onClose={() => setOpenModalAuthData(false)} isEdit={isEditModeProfile} />
        </Modal>
      </View>
    </View>
  )
})
