import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import * as React from 'react'

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false)

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    loadResourcesAndDataAsync()
  }, [])

  async function loadResourcesAndDataAsync() {
    try {
      SplashScreen.preventAutoHideAsync()

      // Load fonts
      await Font.loadAsync({
        'circular-light': require('~/assets/fonts/CircularStd-Light.otf'),
        'circular-regular': require('~/assets/fonts/CircularStd-Book.otf'),
        'circular-medium': require('~/assets/fonts/CircularStd-Medium.otf'),
        'circular-bold': require('~/assets/fonts/CircularStd-Bold.otf'),
        'circular-black': require('~/assets/fonts/CircularStd-Black.otf'),
      })
    } catch (e) {
      // We might want to provide this error information to an error reporting service
      console.warn(e)
    } finally {
      setLoadingComplete(true)
      SplashScreen.hideAsync()
    }
  }

  return isLoadingComplete
}
