import React from 'react'
import { Image as ImageUi } from 'react-native'
import { useTailwind } from 'tailwind-rn'

/**
 * Image component
 */
export const Image = ({ tw: twProps, ...props }) => {
  const tailwind = useTailwind()
  return <ImageUi style={tailwind(twProps)} {...props} />
}
