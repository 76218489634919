import React from 'react'
import { TouchableOpacity as TouchableOpacityUi } from 'react-native'
import PropTypes from 'prop-types'
import { useTailwind } from 'tailwind-rn'

/**
 * TouchableOpacity component
 */
export const TouchableOpacity = ({ children, tw: twProps, ...props }) => {
  const tailwind = useTailwind()
  return (
    <TouchableOpacityUi style={tailwind(twProps)} {...props}>
      {children}
    </TouchableOpacityUi>
  )
}

TouchableOpacity.propTypes = {
  /**
   * Type
   */
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /**
   * isDisabled
   */
  isDisabled: PropTypes.bool,
  /**
   * isSubmit
   */
  isSubmit: PropTypes.bool,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
  /**
   * Size
   */
  size: PropTypes.oneOf(['sm', 'base', 'lg', 'full']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
}

TouchableOpacity.defaultProps = {
  size: 'base',
  type: 'primary',
  label: 'Button',
  onClick: undefined,
}
