import React, { useState, useEffect } from 'react'
import Modal from 'react-native-modal'
import '@expo/match-media'
import { useMediaQuery } from 'react-responsive'

import { View, Icon, TouchableOpacity } from '~/components'

interface Props {
  type?: 'default' | 'full'
  size?: 'xs' | 'sm' | 'base' | 'lg'
  open: boolean
  disableClose: boolean
  onClose: Function
  children: void
}

/**
 * Modal component
 */
export const ModalComponent = ({
  type = 'default',
  children,
  open,
  onClose,
  disableClose = false,
}: Props) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1224,
  })
  const [_open, setOpen] = useState(false)

  useEffect(() => {
    if (open === true) {
      setOpen(open)
    } else {
      setOpen(false)
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const resolveAnimationIn = () => {
    if (type === 'full') {
      if (isTabletOrMobileDevice) {
        return 'slideInUp'
      } else {
        return 'slideInRight'
      }
    } else {
      return 'fadeIn'
    }
  }

  const resolveAnimationOut = () => {
    if (type === 'full') {
      if (isTabletOrMobileDevice) {
        return 'slideOutDown'
      } else {
        return 'slideOutRight'
      }
    } else {
      return 'fadeOut'
    }
  }

  return (
    <Modal
      isVisible={_open}
      animationIn={resolveAnimationIn()}
      animationOut={resolveAnimationOut()}
      hasBackdrop={true}
      backdropOpacity={0.9}
      customBackdrop={
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => onClose()}
          disabled={disableClose === true}
          tw="bg-primary fixed top-0 right-0 bottom-0 left-0"
        />
      }
      animationInTiming={400}
      onBackButtonPress={() => (disableClose === true ? null : onClose())}
      onModalShow={() => (document.documentElement.style.overflow = 'hidden')}
      onModalHide={() => (document.documentElement.style.overflow = 'auto')}
      style={{ margin: 0 }}>
      <View
        tw={{
          'bg-background w-11/12 md:w-116 h-screen overflow-y-auto right-0 absolute top-0 rounded-l-xl':
            isTabletOrMobileDevice === false && type === 'full',
          'bg-background right-0 h-screen overflow-y-auto left-0 absolute top-12 rounded-xl':
            isTabletOrMobileDevice === true && type === 'full',
          'flex justify-center items-center m-4': type === 'default',
        }}>
        <View
          tw={{
            'bg-background p-6 md:p-8 rounded-xl md:w-116': type === 'default',
          }}>
          {/* Close button */}
          {disableClose === false && (
            <>
              {type === 'default' ? (
                <TouchableOpacity tw="absolute -top-2 -right-2 z-10" onPress={handleClose}>
                  <View tw="bg-background flex justify-center items-center cursor-pointer border-2 border-border w-10 h-10 rounded-full">
                    <Icon type="ionic" name="close-outline" tw="text-zinc-200" size={30} />
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity tw="absolute top-5 left-4 z-10" onPress={handleClose}>
                  <View tw="flex justify-center items-center cursor-pointer w-10 h-10">
                    <Icon type="ionic" name="close-outline" tw="text-zinc-100" size={30} />
                  </View>
                </TouchableOpacity>
              )}
            </>
          )}
          {/* Content */}
          {children}
        </View>
      </View>
    </Modal>
  )
}
