import React from 'react'
import PropTypes from 'prop-types'
import RNPickerSelect from 'react-native-picker-select'

const style = {
  fontSize: 15,
  paddingTop: 10,
  paddingHorizontal: 10,
  paddingBottom: 10,
  borderRadius: 8,
  backgroundColor: '#232323',
  borderColor: '#232323',
  color: '#d4d4d8',
}

/**
 * PickerSelect component
 */
export const PickerSelect = ({ placeholder = 'Seleccionar...', items, value = null, onChange }) => {
  return (
    <RNPickerSelect
      placeholder={{ label: placeholder, value: '' }}
      onValueChange={onChange}
      value={value}
      style={{
        inputWeb: style,
        inputIOS: style,
      }}
      items={items}
    />
  )
}

PickerSelect.propTypes = {
  /**
   * Type
   */
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /**
   * isDisabled
   */
  isDisabled: PropTypes.bool,
  /**
   * isSubmit
   */
  isSubmit: PropTypes.bool,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
  /**
   * Size
   */
  size: PropTypes.oneOf(['sm', 'base', 'lg', 'full']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
}

PickerSelect.defaultProps = {
  size: 'base',
  type: 'primary',
  label: 'Button',
  onClick: undefined,
}
