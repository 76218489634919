import { useEffect, useState } from 'react'

import { supabase } from '~/libs/api'
import { authStore } from '~/store'

/**
 * Revisa la sesion de usuario,
 * retorna si usuario esta completo o no
 * (falta de nombre/apellido/telefono)
 * @returns boolean
 */
export default function useHandleUser() {
  const [noFullUser, setNoFullUser] = useState(false)

  useEffect(() => {
    handleUser()
  }, [])

  const handleUser = async () => {
    const handleAuthComplete = async () => {
      if (authStore.isAuth && authStore.isFullUser === false) {
        setNoFullUser(true)
      }
    }

    const resolveUserData = async (session = false) => {
      await authStore.resolveUserData(session)
      await handleAuthComplete()
    }

    const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
      await resolveUserData(session)
    })

    await resolveUserData()

    return () => {
      listener?.unsubscribe()
    }
  }

  return noFullUser
}
