import React, { useState } from 'react'
import { SafeAreaView } from 'react-native'
import { observer } from 'mobx-react-lite'
import { eachDayOfInterval, addDays, set } from 'date-fns'
import { useInsert } from 'react-supabase'
import { useForm, Controller } from 'react-hook-form'

import { Text, View, Button, PickerSelect, Modal, Match, Icon } from '~/components'
import { formatDateHelper } from '~/helpers'

function time(start, end, interval) {
  var s = start.split(':').map((e) => +e)
  var e = end.split(':').map((e) => +e)
  var res = []
  var t = []
  while (!(s[0] == e[0] && s[1] > e[1])) {
    t.push(s[0] + ':' + (s[1] < 10 ? '0' + s[1] : s[1]))
    s[1] += interval
    if (s[1] > 59) {
      s[0] += 1
      s[1] %= 60
    }
  }
  for (var i = 0; i < t.length - 1; i++) {
    res.push({ label: t[i], value: `${t[i]}` })
  }
  return res
}

const hours = time('05:00', '24:00', 30)

const durations = [
  { label: '60 minutos', value: '60' },
  { label: '90 minutos', value: '90' },
  { label: '120 minutos', value: '120' },
]

const locations = [
  { label: 'Las Marías Pádel', value: '1' },
  { label: 'Espacio Pádel', value: '2' },
  { label: 'Club Phoenix', value: '3' },
  { label: 'Physical Pádel', value: '4' },
]

const cats = [
  { label: '6ta iniciante', value: '6_lower' },
  { label: '6ta', value: '6' },
  { label: '6ta avanzada', value: '6_high' },
  { label: '5ta', value: '5' },
  { label: '4ta', value: '4' },
  { label: '3ra', value: '3' },
  { label: '2da', value: '2' },
  { label: '1ra', value: '1' },
]

const types = [
  { label: 'Doble masculino', value: 'double_male' },
  { label: 'Doble femenino', value: 'double_female' },
  { label: 'Doble mixto', value: 'double_mixed' },
  { label: 'Single masculino', value: 'single_male' },
  { label: 'Single femenino', value: 'single_female' },
]

/**
 * Retorna lista de proximos 6 días
 * @returns Array
 */
const getDays = () => {
  const result = eachDayOfInterval({
    start: new Date(),
    end: addDays(new Date(), 6),
  })
  return result.map((item) => {
    return { label: formatDateHelper(item), value: `${item}` }
  })
}

export default observer(function CreateScreen({ navigation }) {
  const [{ count, data, error, fetching }, execute] = useInsert('match')
  const [openModal, setOpenModal] = useState(false)
  const [match, setMatch] = useState({})
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = async ({ date, time, duration, location, category, type }) => {
    try {
      time = time.split(':')
      date = new Date(date)
      date = set(date, { hours: parseInt(time[0]), minutes: parseInt(time[1]) })

      // {
      //   id: 1,
      //   created_at: '2021-12-20T21:55:19-03:00',
      //   duration: '60',
      //   location_id: 3,
      //   date: '2021-12-28T14:30:02-03:00',
      //   category: '6_lower',
      //   type: 'double_male',
      // }
      const model = {
        date,
        duration,
        location_id: location,
        category,
        type,
      }
      setMatch(model)
      setOpenModal(true)
      // await execute(model)
      // navigation.navigate('home')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent' }}>
      <View tw="absolute top-0 right-0 bottom-0 left-0 bg-background p-8">
        <View tw="relative">
          <Text tw="font-black text-xl text-brand self-center mb-4 tracking-wide">
            Crear partido
          </Text>

          <View tw="mb-4">
            <View tw="flex-row items-center mb-2">
              <Icon type="ionic" size={17} name="calendar-outline" tw="text-white mr-2" />
              <Text>Fecha y Hora</Text>
            </View>
            <View tw="flex-row">
              <View tw="flex-row">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="date"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PickerSelect
                      placeholder="Selecciona el día..."
                      items={getDays()}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </View>

              <View tw="flex-row ml-2 items-center">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="time"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PickerSelect
                      placeholder="Selecciona la hora..."
                      items={hours}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Text tw="ml-1 font-normal">hrs</Text>
              </View>
            </View>
          </View>

          <View tw="mb-4">
            <View tw="flex-row items-center mb-2">
              <Icon type="ionic" size={17} name="time-outline" tw="text-white mr-2" />
              <Text>Duración</Text>
            </View>
            <View>
              <View tw="flex-row mb-2">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue="60"
                  name="duration"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PickerSelect
                      placeholder="Seleccionar una duración..."
                      items={durations}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </View>
            </View>
          </View>

          <View tw="mb-4">
            <View tw="flex-row items-center mb-2">
              <Icon type="ionic" size={17} name="location-outline" tw="text-white mr-2" />
              <Text>Lugar</Text>
            </View>
            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="location"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona un lugar..."
                    items={locations}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>
          </View>

          <View tw="mb-6">
            <View tw="flex-row items-center mb-2">
              <Icon type="ionic" size={17} name="tennisball-outline" tw="text-white mr-2" />
              <Text>Categoría</Text>
            </View>
            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="category"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona un categoría..."
                    items={cats}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>
          </View>

          <View tw="mb-8">
            <View tw="flex-row items-center mb-2">
              <Icon type="ionic" size={17} name="tennisball-outline" tw="text-white mr-2" />
              <Text>Tipo de partido</Text>
            </View>
            <View>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="type"
                render={({ field: { onChange, onBlur, value } }) => (
                  <PickerSelect
                    placeholder="Selecciona un tipo..."
                    items={types}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </View>
          </View>

          {Object.keys(errors).length > 0 && (
            <Text tw="mb-2 text-sm">Debes completar todos los campos para continuar</Text>
          )}

          <Button
            showIcon={true}
            label="Crear partido"
            type="primary"
            onPress={handleSubmit(onSubmit)}
          />

          {/* Modal confirmación */}
          <Modal open={openModal}>
            <Text tw="mb-2">¿Confirmas que deseas crear el siguiente partido?</Text>
            <Match data={match} />
            <View tw="mb-2">
              <Button label="Cancelar" onPress={() => setOpenModal(false)} />
            </View>
            <Button label="Confirmar" type="primary" onPress={() => setOpenModal(false)} />
          </Modal>
        </View>
      </View>
    </SafeAreaView>
  )
})
