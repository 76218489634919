import React from 'react'
import { useTailwind } from 'tailwind-rn'
import classnames from 'classnames'

import { Icon, View, Image, TouchableOpacity, Text } from '~/components'

interface Props {
  type?: 'default' | 'primary' | 'outline' | 'success' | 'danger'
  size?: 'xs' | 'sm' | 'base' | 'lg'
  label: string
  icon: any
  disabled: boolean
  showIcon: boolean
  loading: boolean
}

/**
 * Button component
 */
export const Button = ({
  type = 'default',
  size = 'base',
  label = 'Button',
  disabled = false,
  icon,
  showIcon,
  loading,
  ...props
}: Props) => {
  const tailwind = useTailwind()

  const wrapperClass = classnames('relative flex-row justify-center rounded-2xl items-center', {
    'bg-brand': type === 'primary',
    'bg-primary': type === 'default' || disabled === true || loading === true,
    'cursor-not-allowed': disabled === true || loading === true,
    'bg-background border-2 border-primary': type === 'outline',
    'bg-danger': type === 'danger',
    'bg-success': type === 'success',
    'py-1.5 px-4': size === 'xs',
    'py-2 px-10': size === 'sm',
    'py-2 px-14': size === 'base',
    'py-3 px-20': size === 'lg',
  })

  const LoadingSize = size === 'sm' ? 20 : 24

  return (
    <TouchableOpacity {...props} style={tailwind(wrapperClass)} disabled={disabled}>
      <Text
        tw={{
          'font-bold': true,
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-lg': size === 'lg',
          'text-background': type === 'primary',
          'text-zinc-300': type === 'default',
          'text-zinc-600': disabled === true,
        }}>
        {label}
      </Text>
      {loading === true && (
        <View tw="absolute right-4">
          <Image
            source={require('~/assets/images/img-loading.gif')}
            style={{ width: LoadingSize, height: LoadingSize }}
            resizeMode="contain"
          />
        </View>
      )}
      {icon && <View tw="ml-1">{icon}</View>}
      {showIcon && loading === false && (
        <Icon size={20} name="arrow-forward-outline" tw="text-black ml-1" />
      )}
    </TouchableOpacity>
  )
}
