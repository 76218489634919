/**
 * Formatea categorias
 * @param {string} category
 * Example: '6_lower','6','6_high','5','4','3','2','1'
 */
const formatTypeHelper = (type) => {
  if (type === 'double_male') {
    return 'Doble masculino'
  } else if (type === 'double_female') {
    return 'Doble femenino'
  } else if (type === 'double_mixed') {
    return 'Doble mixto'
  } else if (type === 'single_male') {
    return 'Single masculino'
  } else if (type === 'single_female') {
    return 'Single femenino'
  }
}

export default formatTypeHelper
