/**
 * Formatea categorias
 * @param {string} category
 * Example: '6_lower','6','6_high','5','4','3','2','1'
 */
const formatCategoryHelper = (category) => {
  if (category === '6_lower') {
    return {
      number: 6,
      suffix: 'ta',
      label: 'iniciante',
    }
  } else if (category === '6') {
    return {
      number: 6,
      suffix: 'ta',
      label: 'categoría',
    }
  } else if (category === '6_high') {
    return {
      number: 6,
      suffix: 'ta',
      label: 'avanzada',
    }
  } else if (category === '5') {
    return {
      number: 5,
      suffix: 'ta',
      label: 'categoría',
    }
  } else if (category === '4') {
    return {
      number: 4,
      suffix: 'ta',
      label: 'categoría',
    }
  } else if (category === '3') {
    return {
      number: 3,
      suffix: 'ra',
      label: 'categoría',
    }
  } else if (category === '2') {
    return {
      number: 2,
      suffix: 'da',
      label: 'categoría',
    }
  } else if (category === '1') {
    return {
      number: 1,
      suffix: 'ra',
      label: 'categoría',
    }
  } else {
    return '-'
  }
}

export default formatCategoryHelper
