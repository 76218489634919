import React, { useState, useEffect } from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'

import {
  Text,
  View,
  Icon,
  Button,
  Match,
  Modal,
  TouchableOpacity,
  ImageBackground,
  ModalAuth,
} from '~/components'
import { supabase } from '~/libs/api'
import { useStore } from '~/store/StoreProvider'

export default observer(function MatchScreen({ data: _data, onClose }) {
  const { authStore } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const [isMatchClosed, setIsMatchClosed] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [hasRequested, setHasRequested] = useState(false)
  const [hasRequestConfirmed, setHasRequestConfirmed] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalRequest, setOpenModalRequest] = useState(false)
  const [openModalClose, setOpenModalClose] = useState(false)
  const [openModalCancelRequest, setOpenModalCancelRequest] = useState(false)
  const [openModalAuth, setOpenModalAuth] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (_data) {
      // Si es owner de partido
      if (_data?.owner_id === authStore?.userData?.id) {
        setIsOwner(true)
      }
      // Si partido esta cerrado
      if (_data?.closed === true) {
        setIsMatchClosed(true)
      }
      // Revisa si usuario actual tiene solicitudes o fue confirmado
      if (isOwner === false) {
        setHasRequested(_data?.requests?.some((item) => item.user_id === authStore.userData.id))
        setHasRequestConfirmed(
          _data?.requests?.some(
            (item) => item.user_id === authStore.userData.id && item.confirmed === true
          )
        )
      }
      setData(_data)
    }
  }, [_data])

  // Resuelve consulta match solo si es participante del partido
  // De esta forma se obtienen los telefonos de los jugadores
  useEffect(() => {
    const handleRequest = async () => {
      if (isOwner === true || (hasRequestConfirmed === true && data?.id)) {
        const { data: response } = await supabase
          .from('matches')
          .select(
            [
              '*',
              'locations!inner(*)',
              'users!owner_id(id,first_name,last_name,phone)',
              'requests!left(*, users!left(id,first_name,last_name,phone))',
            ].join(',')
          )
          .eq('id', data?.id)
        if (response && response[0]) {
          setData(response[0])
        }
      }
    }
    handleRequest()
  }, [_data, isOwner, hasRequestConfirmed])

  const handleRequest = async (withPatner = false) => {
    try {
      setIsLoading(true)
      await supabase
        .from('requests')
        .insert([{ match_id: data?.id, user_id: authStore.userData.id, with_patner: withPatner }])
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      setOpenModalRequest(false)
    }
  }

  const handleCancelRequest = async () => {
    try {
      setIsLoading(true)
      await supabase
        .from('requests')
        .delete()
        .match({ match_id: data?.id, user_id: authStore.userData.id })
    } catch (error) {
      console.error(error)
    } finally {
      setOpenModalCancelRequest(false)
      setIsLoading(false)
    }
  }

  const handleDeleteMatch = async () => {
    try {
      if (isOwner === false) return
      setIsLoading(true)
      await supabase.from('requests').delete().match({ match_id: data?.id })
      await supabase.from('matches').delete().match({ id: data?.id })
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      setOpenModalDelete(false)
    }
  }

  const handleConfirmRequest = async (playerId) => {
    try {
      setIsLoading(true)
      await supabase
        .from('requests')
        .update({ confirmed: true })
        .match({ match_id: data?.id, user_id: playerId })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleRevokeRequest = async (playerId) => {
    try {
      setIsLoading(true)
      await supabase
        .from('requests')
        .update({ confirmed: false })
        .match({ match_id: data?.id, user_id: playerId })
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseMatch = async () => {
    try {
      setIsLoading(true)
      await supabase.from('matches').update({ closed: true }).match({ id: data?.id })
      setIsMatchClosed(true)
    } catch (error) {
      console.error(error)
    } finally {
      setOpenModalClose(false)
      setIsLoading(false)
    }
  }

  const handleShareMatch = () => {
    const message = encodeURI(
      `Te invito a unirte a mi partido en PlayPat ${process.env.URL}/?id=${data?.id}`
    )
    window.open(`https://api.whatsapp.com/send?text=${message}`, '_blank')
  }

  const handleJoin = () => {
    if (authStore.isAuth === true) {
      setOpenModalRequest(true)
    } else {
      setOpenModalAuth(true)
    }
  }

  // Numero de jugadores confirmados
  const playersConfirmed = () => {
    const countConfirmed =
      data?.requests?.length > 0 &&
      data?.requests?.reduce((acc, item) => {
        let count = 0
        if (item.confirmed === true) {
          count = item.confirmed === true && item.with_patner === true ? 2 : 1
        }
        return acc + count
      }, 0)
    return 4 - data.players_slots + countConfirmed
  }

  // Jugadores extras
  const countPlayers = () => {
    const countConfirmed =
      data?.requests?.length > 0 && data?.requests?.filter((item) => item.confirmed === true).length
    return playersConfirmed() - countConfirmed - 1
  }

  // Abre contacto con usuario en WhatsApp
  const handleContactPhone = (phone: string) => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=%C2%A1Hola,%20te%20contacto%20por%20el%20partido%20reci%C3%A9n%20cerrado%20por%20PlayPat!`,
      '_blank'
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent' }}>
      <View tw="flex-1 relative pb-20">
        {data && (
          <>
            <ImageBackground
              tw="py-10"
              resizeMode="cover"
              source={require('~/assets/images/bg-header-2.jpg')}>
              {/* Compartir */}
              <TouchableOpacity
                tw="absolute top-6 right-6 rounded-lg items-center justify-center h-8 w-8"
                onPress={handleShareMatch}>
                <Icon type="ionic" size={20} name="share-social-outline" tw="text-zinc-200" />
              </TouchableOpacity>

              {/* Detalles del partido */}
              <View tw="mt-10 -mb-24 mx-6">
                <Match data={data} showMore={true} />
              </View>
            </ImageBackground>

            <View tw="px-6 mt-20">
              {/* Cerrar partido */}
              {isOwner === true && playersConfirmed() === 4 && isMatchClosed === false && (
                <View tw="p-6 bg-primary rounded-xl mb-3 ">
                  <Text tw="mb-2">
                    ¡Ya completaste a todos los jugadores para cerrar tu partido!
                  </Text>
                  <View tw="mb-3">
                    <Button
                      type="primary"
                      size="sm"
                      label="Cerrar"
                      onPress={() => setOpenModalClose(true)}
                    />
                  </View>
                  <Text tw="text-zinc-400 text-xs">
                    Al cerrar un partido confirmas a todos los jugadores invitados que se han unido,
                    además ellos podrán ver tu teléfono para que se puedan comunicar contigo.
                  </Text>
                </View>
              )}

              {/* Solicitar unirse al partido */}
              {isOwner === false && isMatchClosed === false && (
                <View tw="mb-2">
                  {authStore.isAuth === false && (
                    <Text tw="text-xs text-zinc-400 mb-2 self-center">
                      Primero debes iniciar sesión para unirte a un partido
                    </Text>
                  )}

                  {hasRequested === false && (
                    <Button
                      type="primary"
                      label="Me quiero unir"
                      onPress={handleJoin}
                      icon={
                        <Icon type="ionic" size={18} name="arrow-up-outline" tw="text-background" />
                      }
                    />
                  )}

                  {hasRequested === true && (
                    <Button
                      type="danger"
                      label="Cancelar"
                      size="sm"
                      onPress={() => setOpenModalCancelRequest(true)}
                    />
                  )}
                </View>
              )}

              {/* Respuestas para jugador externo */}
              {hasRequested && hasRequestConfirmed === false && (
                <View tw="flex-row items-center justify-center">
                  <Text tw="text-success text-xs">Solicitud para unirte enviada</Text>
                </View>
              )}
              {hasRequested && hasRequestConfirmed === true && isMatchClosed === false && (
                <View tw="flex-row items-center justify-center">
                  <Text tw="text-success text-xs">
                    Solicitud para unirte aceptada (esperando que el creador cierre el partido)
                  </Text>
                </View>
              )}

              {/* Partido ya cerrado por creador */}
              {isMatchClosed === true && (
                <>
                  <View tw="mb-3">
                    {isOwner === false && hasRequestConfirmed === false && (
                      <View tw="flex-row items-center">
                        <View tw="w-3 h-3 bg-danger rounded-full mr-1"></View>
                        <Text tw="text-danger">Este partido ya está cerrado</Text>
                      </View>
                    )}

                    {isOwner === false && hasRequestConfirmed === true && (
                      <>
                        <Text tw="mb-1 text-success text-base">
                          ¡Partido cerrado, ahora a jugar!
                        </Text>
                        {data?.users?.phone && (
                          <TouchableOpacity onPress={() => handleContactPhone(data?.users?.phone)}>
                            <Text tw="text-sm text-zinc-400">
                              Si lo deseas puedes contactarte con{' '}
                              <Text tw="font-bold text-sm">
                                {data?.users?.first_name} {data?.users?.last_name}
                              </Text>{' '}
                              vía WhatsApp por acá{' '}
                              <Icon type="ionic" size={17} name="logo-whatsapp" tw="text-success" />
                            </Text>
                          </TouchableOpacity>
                        )}
                      </>
                    )}

                    {isOwner === true && (
                      <>
                        <Text tw="mb-1 text-success text-base">
                          ¡Partido cerrado, ahora a jugar!
                        </Text>
                        <Text tw="text-sm">
                          Ya cuentas con todos los jugadores para tu partido.
                        </Text>
                        <Text tw="text-sm text-zinc-400">
                          Si lo deseas te puedes contactar con cada jugador en su icono de WhatsApp.
                        </Text>
                      </>
                    )}
                  </View>
                </>
              )}

              {/* Resumen jugadores confirmados */}
              <View tw="flex-row items-center justify-start my-2">
                <Icon type="ionic" size={16} name="people-outline" tw="mr-1" />
                <Text tw="text-xs">Jugadores {playersConfirmed()}/4</Text>
              </View>

              {/* Jugadores confirmados */}
              <View tw="mb-3">
                <View tw="grid gap-y-2">
                  {/* Jugador creador */}
                  <View tw="flex-row items-center justify-between bg-primary py-3 px-4 rounded-xl">
                    <View tw="flex-row">
                      <Icon type="ionic" size={17} name="person-outline" tw="mr-2" />
                      <Text tw="text-sm">
                        {data?.users?.first_name} {data?.users?.last_name}
                      </Text>
                      <Text tw="text-brand ml-2 text-sm">{isOwner ? '(Tú)' : '(Creador)'}</Text>
                    </View>
                    <Icon
                      type="ionic"
                      size={17}
                      name="checkmark-done-outline"
                      tw="text-brand ml-1"
                    />
                  </View>

                  {/* Jugadores acompañantes creador */}
                  {countPlayers() > 0 &&
                    [...Array(countPlayers()).keys()].map((key) => (
                      <View
                        tw="flex-row items-center justify-between bg-primary py-3 px-4 rounded-xl"
                        key={key}>
                        <View tw="flex-row">
                          <Icon type="ionic" size={17} name="person-outline" />
                          <Text tw="ml-2 text-sm">Jugador acompañante</Text>
                        </View>
                        <Icon
                          type="ionic"
                          size={17}
                          name="checkmark-done-outline"
                          tw="text-brand ml-1"
                        />
                      </View>
                    ))}

                  {/* Resto de jugadores */}
                  {data?.requests?.length > 0 &&
                    data?.requests
                      .filter((item) => item.confirmed === true)
                      .map((item, key) => (
                        <View
                          tw="flex-row items-center justify-between bg-primary py-2 px-4 rounded-xl"
                          key={key}>
                          <View tw="flex-row">
                            <Icon type="ionic" size={17} name="person-outline" />
                            <Text tw="ml-2 text-sm">
                              {item?.users?.first_name} {item?.users?.last_name}
                            </Text>
                            {item?.users?.id === authStore.userData.id && (
                              <Text tw="text-brand ml-2 text-sm">(Tú)</Text>
                            )}
                          </View>
                          <View tw="flex-row gap-1 items-center">
                            {isOwner === true && isMatchClosed === true && item?.users?.phone && (
                              <TouchableOpacity
                                onPress={() => handleContactPhone(item?.users?.phone)}>
                                <Icon
                                  type="ionic"
                                  size={17}
                                  name="logo-whatsapp"
                                  tw="text-success"
                                />
                              </TouchableOpacity>
                            )}
                            {isOwner === true && isMatchClosed === false && (
                              <Button
                                size="xs"
                                type="primary"
                                label="Retirar"
                                onPress={() => handleRevokeRequest(item?.users?.id)}
                              />
                            )}
                            <Icon
                              type="ionic"
                              size={17}
                              name="checkmark-done-outline"
                              tw="text-brand ml-1"
                            />
                          </View>
                        </View>
                      ))}
                </View>
              </View>

              {/* Jugadores solicitando unirse al partido */}
              {isOwner === true && (
                <>
                  {data?.requests?.length > 0 &&
                  data?.requests?.some((item) => item.confirmed === false) ? (
                    <>
                      <View tw="flex-row items-center mb-2">
                        <Icon type="ionic" size={14} name="person-add-outline" tw="mr-1" />
                        <Text tw="text-zinc-400 text-xs">Jugadores solicitando unirse</Text>
                      </View>
                      <View tw="py-2 px-4 bg-primary rounded-xl grid gap-y-2">
                        {data?.requests?.length > 0 &&
                          data?.requests
                            .filter((item) => item.confirmed === false)
                            .map((item, key) => (
                              <View tw="flex-row justify-between" key={key}>
                                <View tw="flex-row items-center">
                                  <Icon type="ionic" size={17} name="person-outline" />
                                  <Text tw="ml-2 mr-2 text-sm">
                                    {item?.users?.first_name} {item?.users?.last_name}
                                  </Text>
                                  {item?.with_patner && <Text tw="text-sm">(más patner)</Text>}
                                </View>
                                <Button
                                  size="xs"
                                  type={playersConfirmed() >= 4 ? 'outline' : 'primary'}
                                  label="Aceptar"
                                  onPress={() => handleConfirmRequest(item?.users?.id)}
                                  disabled={playersConfirmed() >= 4}
                                />
                              </View>
                            ))}
                      </View>
                    </>
                  ) : (
                    <>
                      {isMatchClosed === false && (
                        <Text tw="text-zinc-400 text-xs">
                          Aún no hay jugadores solicitando unirse
                        </Text>
                      )}
                    </>
                  )}
                </>
              )}
            </View>

            {/* Opciones */}
            <View tw="px-6 mt-4">
              {isOwner === true && isMatchClosed === false && (
                <>
                  {/* Editar partido como creador */}
                  {/* <View tw="mb-3">
                  <Button
                    size="sm"
                    label="Editar"
                    onPress={() => navigation.navigate('edit', { id: data?.id })}
                  />
                </View> */}

                  {/* Eliminar partido como creador  */}
                  <View tw="mb-3">
                    <Button
                      type="outline"
                      size="sm"
                      label="Eliminar"
                      onPress={() => setOpenModalDelete(true)}
                    />
                  </View>
                </>
              )}
            </View>

            {/* Modal eliminación partido */}
            <Modal open={openModalDelete} onClose={() => setOpenModalDelete(false)}>
              <Text tw="mb-3">¿Confirmas que deseas eliminar este partido?</Text>
              <View tw="mb-3">
                <Button label="Confirmar" type="primary" onPress={handleDeleteMatch} />
              </View>
              <Button label="Cancelar" onPress={() => setOpenModalDelete(false)} />
            </Modal>

            {/* Modal solicitar unirse a partido */}
            <Modal open={openModalRequest} onClose={() => setOpenModalRequest(false)}>
              <Text tw="mb-3">Estas solicitando unirte al siguiente partido</Text>
              <View tw="mb-4">
                <Match data={data} />
              </View>
              <View tw="mb-3">
                <Button
                  label={`${playersConfirmed() < 3 ? 'Solicitar sólo' : 'Solicitar'}`}
                  type="primary"
                  onPress={() => handleRequest()}
                  disabled={isLoading}
                />
              </View>

              {playersConfirmed() < 3 && (
                <>
                  <View tw="mb-3">
                    <Button
                      label="Solicitar con mi partner"
                      onPress={() => handleRequest(true)}
                      disabled={isLoading}
                    />
                  </View>
                  <View tw="mb-3">
                    <Text tw="text-zinc-400 text-sm">
                      Puedes solicitar unirte sólo o con tu partner, para llenar dos cupos en el
                      partido.
                    </Text>
                  </View>
                </>
              )}
              <Button
                size="sm"
                label="Cancelar"
                type="outline"
                onPress={() => setOpenModalRequest(false)}
              />
            </Modal>

            {/* Modal cancelar solicitud unirse a partido */}
            <Modal open={openModalCancelRequest} onClose={() => setOpenModalCancelRequest(false)}>
              <Text tw="mb-3">¿Seguro deseas cancelar tu solicitud a este partido?</Text>
              <View tw="mb-3">
                <Button label="Cancelar" type="primary" onPress={handleCancelRequest} />
              </View>
              <Button size="sm" label="Volver" onPress={() => setOpenModalCancelRequest(false)} />
            </Modal>

            {/* Modal cerrar/terminar partido */}
            <Modal open={openModalClose} onClose={() => setOpenModalClose(false)}>
              <Text tw="mb-3">
                ¿Seguro deseas cerrar este partido con los jugadores confirmados?
              </Text>
              <View tw="mb-3">
                <Button label="Cerrar" type="primary" onPress={handleCloseMatch} showIcon={true} />
              </View>
              <Button size="sm" label="Cancelar" onPress={() => setOpenModalClose(false)} />
            </Modal>

            {/* Modal autenticación */}
            <Modal open={openModalAuth} onClose={() => setOpenModalAuth(false)}>
              <ModalAuth onClose={() => setOpenModalAuth(false)} showMore={true} />
            </Modal>
          </>
        )}
      </View>
    </SafeAreaView>
  )
})
