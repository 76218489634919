import React from 'react'
import { Ionicons, Feather } from '@expo/vector-icons'
import { useTailwind } from 'tailwind-rn'
import classnames from 'classnames'

interface Props {
  type?: 'ionic' | 'feather'
  size?: number
  name?: string
  tw: any
}

/**
 * Icon component
 */
export const Icon = ({ name = 'users', type = 'ionic', size = 18, tw: twProps }: Props) => {
  const tailwind = useTailwind()
  const superProps = {
    type,
    name,
    size,
    style: tailwind(classnames(['text-zinc-300', twProps])),
  }

  if (type === 'ionic') {
    return <Ionicons {...superProps} />
  } else if (type === 'feather') {
    return <Feather {...superProps} />
  }
}
