import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { supabase } from '~/libs/api'
import { View, Text, InputText, Button } from '~/components'
import { useStore } from '~/store/StoreProvider'

export default function ModalAuthData({ onClose, isEdit }) {
  const { authStore } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: authStore?.userData?.first_name || '',
      lastName: authStore?.userData?.last_name || '',
      phone: authStore?.userData?.phone || '',
    },
  })
  const onSubmit = (data) => handleLogin(data)

  const handleLogin = async (data) => {
    try {
      setIsLoading(true)
      const { firstName, lastName, phone } = data
      const model = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        phone: parseInt(phone),
      }
      const { error } = await supabase
        .from('users')
        .update(model)
        .match({ id: authStore.userData.id })

      if (error) throw error
      authStore.updateUserData(model)
      onClose()
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <>
      <Text tw="mb-3 text-brand">
        {isEdit === true
          ? 'Editar perfil'
          : 'Bienvenido a PlayPat, por favor completa tus datos personales para continuar'}
      </Text>

      <View tw="grid gap-y-3 mb-4">
        <View>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => value.trim().length > 2,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                placeholder="Nombre"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            )}
            name="firstName"
          />
          {errors.firstName && <Text tw="text-xs text-danger mt-2">Campo requerido</Text>}
        </View>

        <View>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => value.trim().length > 2,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                placeholder="Apellido"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            )}
            name="lastName"
          />
          {errors.lastName && <Text tw="text-xs text-danger mt-2">Campo requerido</Text>}
        </View>

        <View>
          <Controller
            control={control}
            rules={{
              required: true,
              pattern: /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                placeholder="Teléfono (9 99999999)"
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
              />
            )}
            name="phone"
          />
          {errors.phone && <Text tw="text-xs text-danger mt-2">Campo requerido</Text>}
          <Text tw="text-xs mt-2">
            (Tu teléfono solo será expuesto al momento de cerrar un partido con otros jugadores)
          </Text>
        </View>
      </View>

      <View>
        <Button
          label="Guardar"
          type="primary"
          onPress={handleSubmit(onSubmit)}
          disabled={isLoading}
          loading={isLoading}
        />
      </View>
    </>
  )
}
