import * as React from 'react'
import { StyleSheet, View, Text, ScrollView } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { Ionicons } from '@expo/vector-icons'

import { Button, PickerSelect } from '~/components'

let fontSize = 24
let color = 'white'
let paddingVertical = 6

export default function UiScreen() {
  const tailwind = useTailwind()

  return (
    <View style={tailwind('flex-1 p-8 bg-background')}>
      <ScrollView>
        {/* Texts */}
        <View>
          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_100Thin',
            }}>
            Josefin Sans Thin (100)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_200ExtraLight',
            }}>
            Josefin Sans Extra Light (200)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_300Light',
            }}>
            Josefin Sans Light (300)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_400Regular',
            }}>
            Josefin Sans Regular (400)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_500Medium',
            }}>
            Josefin Sans Medium (500)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_600SemiBold',
            }}>
            Josefin Sans Semi Bold (600)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_700Bold',
            }}>
            Josefin Sans Bold (700)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_100Thin_Italic',
            }}>
            Josefin Sans Thin Italic (100)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_200ExtraLight_Italic',
            }}>
            Josefin Sans Extra Light Italic (200)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_300Light_Italic',
            }}>
            Josefin Sans Light Italic (300)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_400Regular_Italic',
            }}>
            Josefin Sans Italic (400)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_500Medium_Italic',
            }}>
            Josefin Sans Medium Italic (500)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_600SemiBold_Italic',
            }}>
            Josefin Sans Semi Bold Italic (600)
          </Text>

          <Text
            style={{
              color,
              fontSize,
              paddingVertical,
              // Note the quoting of the value for `fontFamily` here; it expects a string!
              fontFamily: 'JosefinSans_700Bold_Italic',
            }}>
            Josefin Sans Bold Italic (700)
          </Text>
        </View>

        {/* Line */}
        <View style={tailwind('h-0.5 w-full bg-gray-600 my-4')}></View>

        {/* Buttons */}
        <View>
          <View style={tailwind('mb-4')}>
            <Button label="Crear partido" type="primary" onPress={() => alert('press')} />
          </View>
          <View style={tailwind('mb-4')}>
            <Button size="sm" label="Crear partido" type="primary" onPress={() => alert('press')} />
          </View>
          <View style={tailwind('mb-4')}>
            <Button
              size="sm"
              type="secondary"
              label="Crear partido"
              onPress={() => alert('press')}
            />
          </View>
        </View>

        {/* Picker */}
        <View>
          <PickerSelect
            items={[
              { label: 'a', value: 0 },
              { label: 'b', value: 1 },
            ]}
          />
        </View>
      </ScrollView>
    </View>
  )
}
