import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { StoreProvider } from './src/store/StoreProvider'
import { TailwindProvider } from 'tailwind-rn'
import utilities from './tailwind.json'

import { createClient } from '@supabase/supabase-js'
import { Provider } from 'react-supabase'

import { useCachedResources, useColorScheme } from '~/hooks'
import Navigation from '~/navigation'

const client = createClient(process.env.SUPABASE_URL, process.env.SUPABASE_KEY)

export default function App() {
  const isLoadingComplete = useCachedResources()
  const colorScheme = useColorScheme()

  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <Provider value={client}>
        <StoreProvider>
          <TailwindProvider utilities={utilities}>
            <SafeAreaProvider>
              <StatusBar />
              <Navigation colorScheme={colorScheme} />
            </SafeAreaProvider>
          </TailwindProvider>
        </StoreProvider>
      </Provider>
    )
  }
}
