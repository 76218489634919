import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { useStore } from '~/store/StoreProvider'
import { View, Text, Icon } from '~/components'
import {
  formatDateHelper,
  formatTimeHelper,
  formatCategoryHelper,
  formatTypeHelper,
} from '~/helpers'

const locations = [
  { label: 'Las Marías Pádel', value: '1' },
  { label: 'Espacio Pádel', value: '2' },
  { label: 'Club Phoenix', value: '3' },
  { label: 'Physical Pádel', value: '4' },
  { label: 'Valdivia Pádel Center', value: '5' },
  { label: 'Centro Español', value: '6' },
]

export default observer(function Match({
  data,
  showConfirmed,
  showMore,
}: {
  data: any
  showConfirmed: boolean
  showMore: boolean
}) {
  const { authStore } = useStore()
  const [location, setLocation] = useState(null)
  const [isMatchClosed, setIsMatchClosed] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [hasRequested, setHasRequested] = useState(false)
  const [hasRequestConfirmed, setHasRequestConfirmed] = useState(false)

  useEffect(() => {
    if (data) {
      // Si es owner de partido
      setIsOwner(data?.owner_id === authStore?.userData?.id || false)

      // Si partido esta cerrado
      setIsMatchClosed(data?.closed === true || false)

      // Revisa si usuario actual tiene solicitudes o fue confirmado
      setHasRequested(
        data?.requests?.some((item) => item.user_id === authStore.userData.id) || false
      )
      setHasRequestConfirmed(
        data?.requests?.some(
          (item) => item.user_id === authStore.userData.id && item.confirmed === true
        ) || false
      )
    }
  }, [data])

  // Numero de jugadores confirmados
  const playersConfirmed = () => {
    const countConfirmed =
      data?.requests?.length > 0 &&
      data?.requests?.reduce((acc, item) => {
        let count = 0
        if (item.confirmed === true) {
          count = item.confirmed === true && item.with_patner === true ? 2 : 1
        }
        return acc + count
      }, 0)
    return 4 - data.players_slots + countConfirmed
  }

  useEffect(() => {
    if (data) {
      if (data.locations) {
        setLocation(data.locations.name)
      } else if (data.location_id) {
        const _location = locations.find((item) => item.value === data.location_id)
        if (_location?.label) {
          setLocation(_location?.label)
        }
      }
    }
  }, [data])

  return (
    <View tw="bg-primary rounded-xl grid gap-y-0.5">
      <View tw="p-6 rounded-t-xl flex-row gap-2">
        {/* Categoría */}
        <View tw="w-1/3 items-center">
          <View tw="flex-row items-center">
            <Text tw="text-3xl">{formatCategoryHelper(data.category).number}</Text>
            <Text tw="text-sm mt-2 ml-0.5">{formatCategoryHelper(data.category).suffix}</Text>
          </View>
          <View tw="-mt-1">
            <Text tw="text-sm">{formatCategoryHelper(data.category).label}</Text>
          </View>
        </View>

        <View tw="w-2/3">
          {/* Tipo */}
          <View>
            <Text tw="text-lg">{formatTypeHelper(data.type)}</Text>
          </View>

          {/* Ubicación */}
          {location && (
            <View>
              <View tw="flex-row items-center">
                <Icon type="feather" name="map-pin" size={14} tw="mr-1 text-zinc-400" />
                <Text tw="text-sm text-zinc-400">{location}</Text>
              </View>
              {showMore && data.court_number && (
                <Text tw="text-xs text-zinc-400 ml-5">Cancha {data.court_number}</Text>
              )}
            </View>
          )}
        </View>
      </View>

      <View tw="px-6 py-4 bg-[#2B2A2A] rounded-b-xl flex-row justify-around items-center">
        {/* Dia */}
        <View tw="flex-row items-center justify-center">
          <Icon type="feather" name="calendar" size={14} tw="mr-1 text-zinc-400" />
          <Text tw="text-sm text-zinc-400">
            {formatDateHelper(data.date, showMore === true ? 'EEEE d' : 'EEEE d')}
          </Text>
        </View>

        <View tw="w-0.5 h-3 bg-zinc-700 mt-1"></View>

        {/* Hora */}
        <View tw="flex-row items-center justify-center">
          <Icon type="feather" name="clock" size={14} tw="mr-1 text-zinc-400" />
          <Text tw="text-sm text-zinc-400">{formatTimeHelper({ date: data.date })}</Text>
          {showMore === true && data.duration && (
            <>
              <Text tw="text-sm text-zinc-400"> a </Text>
              <Text tw="text-sm text-zinc-400">
                {formatTimeHelper({ date: data.date, add: parseInt(data.duration) })}
              </Text>
            </>
          )}
        </View>

        {/* Jugadores confirmados */}
        {showConfirmed === true && (
          <>
            <View tw="w-0.5 h-3 bg-zinc-700 mt-1"></View>
            <View tw="flex-row items-center justify-center">
              <Icon type="ionic" name="people-outline" size={19} tw="mr-1 text-zinc-400" />
              <View
                tw={{
                  'bg-orange-400': true,
                  'bg-red-500': false,
                }}></View>
              <Text tw="text-sm text-zinc-400">{playersConfirmed()}/4</Text>
            </View>
          </>
        )}
      </View>

      <View tw="absolute top-4 right-4 grid gap-y-1.5">
        {isOwner === true && <View tw="w-3 h-3 bg-brand rounded-full"></View>}
        {isMatchClosed === true && <View tw="w-3 h-3 bg-danger rounded-full"></View>}
        {hasRequested === true && hasRequestConfirmed === false && (
          <Icon type="ionic" size={14} name="checkmark-outline" tw="text-brand" />
        )}
        {hasRequestConfirmed === true && (
          <Icon type="ionic" size={14} name="checkmark-done-outline" tw="text-brand" />
        )}
      </View>
    </View>
  )
})
