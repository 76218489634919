import * as React from 'react'
import { View, ScrollView } from 'react-native'

import { Text, Match, Image, Icon } from '~/components'

const matchs = [
  {
    time: '07:00 - 08:00',
    location: 'Las Marías Pádel',
    category: '6ta',
    players: '4/4',
    left: 0,
  },
  {
    time: '08:00 - 09:00',
    location: 'Espacio Padel',
    category: '5ta',
    players: '1/4',
    left: 3,
  },
]

export default function ProfileScreen() {
  return (
    <View tw="flex-1 bg-black">
      {/* <Image
        resizeMode="cover"
        source={require('~/assets/images/player.jpg')}
        tw="absolute top-0 h-60 w-full opacity-50"
      /> */}
      <View tw="flex-1 px-8 mt-14">
        <View tw="justify-center items-center h-48">
          <Icon type="ionic" size={80} name="person-circle-outline" tw="text-brand" />
          <Text tw="text-2xl font-black">Raúl Hernández M.</Text>
          <Text tw="text-base">20 partidos jugados</Text>
        </View>

        {/* <View style={tw('h-0.5 w-full bg-gray-600 my-4')}></View> */}

        {/* Partidos confirmados */}
        <View tw="mb-4">
          <Text tw="text-gray-400">Partidos confirmados</Text>
        </View>

        <ScrollView>
          {matchs.map((item, key) => (
            <Match item={item} key={key} />
          ))}
        </ScrollView>
      </View>
    </View>
  )
}
