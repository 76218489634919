import { Ionicons } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as React from 'react'
import { ColorSchemeName } from 'react-native'

import NotFoundScreen from '../screens/NotFoundScreen'
import {
  HomeScreen,
  CreateScreen,
  EditScreen,
  MatchScreen,
  ProfileScreen,
  UiScreen,
} from '~/screens'
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types'

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  )
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>()

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      {/* <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} /> */}
      <Stack.Group
        screenOptions={{
          headerTransparent: false,
          headerShown: false,
        }}>
        <Stack.Screen
          name="create"
          component={CreateScreen}
          options={{
            title: 'Crear partido',
          }}
        />
        <Stack.Screen
          name="match"
          component={MatchScreen}
          options={{
            title: 'Partido',
          }}
        />
        <Stack.Screen
          name="edit"
          component={EditScreen}
          options={{
            title: 'Editar partido',
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  )
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>()

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: '#d1fb40',
        headerShown: false,
        tabBarStyle: {
          display: 'none',
          padding: 10,
          backgroundColor: 'black',
          borderTopWidth: 0,
          elevation: 0,
        },
      }}>
      <BottomTab.Screen
        name="home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<'Home'>) => ({
          title: 'PlayPat - Encuentra a tu patner de pádel',
          tabBarIcon: ({ color }) => <TabBarIcon name="tennisball-outline" color={color} />,
        })}
      />
      <BottomTab.Screen
        name="profile"
        component={ProfileScreen}
        options={{
          title: 'Yo',
          tabBarIcon: ({ color }) => <TabBarIcon name="person-outline" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="ui"
        component={UiScreen}
        options={{
          title: 'Ui',
          tabBarIcon: ({ color }) => <TabBarIcon name="build-outline" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  )
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: 1 }} {...props} />
}
